.roll-rolled {
  background: repeat 90% url('assets/images/roll-rolled.image.svg');
}

.stripe-dark-input {
  border: 2px solid #666666;
  border-radius: 16px;
}

.InputElement {
  padding: 116px 14px !important;
}

.bg-roll {
  display: flex;
  position: relative;
  overflow: hidden;
}

.bg-roll::before {
  content: '&nbsp;';
  display: block;
  position: absolute;
  top: -200px;
  left: -100px;
  background: repeat 44% 30% url('assets/images/roll-rolled.image.svg');
  background-size: 128px;
  background-color: #acfb68;
  transform: rotate(-15deg);
  width: 600px;
  height: 600px;
  z-index: 0;
}

.bg-overview {
  overflow: hidden !important;
  position: relative;
  color: #fff;
  border-radius: 7.5px;
}

.bg-overview::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(239, 153, 233);
  z-index: -2;
}

.bg-overview-photos::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url('assets/images/bg-photos.image.svg');
  background-size: cover;
}

.bg-overview-videos::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url('assets/images/bg-videos.image.svg');
  background-size: cover;
}

.bg-overview-messages::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url('assets/images/bg-messages.image.svg');
  background-size: cover;
}

.bg-roll .MuiGrid-root {
  z-index: 1;
}

.rule-bg-mobile::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 40%;
  background: repeat-x 0 0 url('assets/images/dm-rules-background.image.svg');
  background-size: cover;
  opacity: 0.7;
}

.rule-bg-desk::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 103%;
  height: 40%;
  background: repeat-x 10% 0 url('assets/images/dm-rules-background.image.svg');
  background-size: cover;
  transform: translate(-5%, -10%);
  opacity: 0.7;
}

.drawer-list-light {
  padding: 0;
}

.conversation-selected {
  background: rgba(172, 251, 104, 0.25);
}

.conversation-selected .creator-item:hover {
  background: none !important;
}

.payment-button {
  margin: 8px 0;
}
