html {
  margin: 0;
  padding: 0;
  min-height: 100dvh;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100dvh;
  background: #272727;
}

main {
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 100dvh;
  max-width: 1400px;
  margin: 0 auto;
}

.wrapper-overflow {
  max-height: 100dvh;
  overflow-y: auto;
}

main::-webkit-scrollbar {
  background: transparent;
  width: 4px;
}

main::-webkit-scrollbar-thumb {
  background: rgba(102, 102, 102, .75);
}

body::-webkit-scrollbar {
  background: transparent;
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(102, 102, 102, .75);
}

.styled-scrollbar::-webkit-scrollbar {
  background: transparent;
  width: 4px;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(102, 102, 102, .75);
}

.styled-scrollbar::-webkit-scrollbar:horizontal {
  background: transparent;
  height: 4px;
}

.styled-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: rgba(102, 102, 102, .75);
}

@media screen and (max-width: 899px) {
  main {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Avenir;
  src: url("assets/fonts/avenir-black.otf") format("opentype");
}

@font-face {
  font-family: Modak;
  src: url("assets/fonts/modak-regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir-Regular';
  src: url("assets/fonts/avenir-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/open-sans-bold.ttf") format("truetype");
  font-weight: 700;
}